import { Layout } from "components/layout";

export const Custom404 = () => {
  return (
    <Layout pageName='Not Found'>
      <article className='text-center py-20 space-y-16 bg-green-light text-white'>
        <h1 className='header text-4xl'>Page not found</h1>
        <p>There doesn't seem to be anything here!?</p>
      </article>
    </Layout>
  );
};

export default Custom404;
